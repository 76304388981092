<template>
  <div class="productos-consumo mx-2">
    <v-row>
      <v-col cols="12" md="12" sm="12">
        <v-card>
          <v-card-title>Informe de producto de consumo</v-card-title>
          <v-card-subtitle>
            Desde este apartado, podrás ver los movimientos de tus productos de
            consumo y su balance general.
          </v-card-subtitle>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" md="4" sm="6">
                <v-menu
                  v-model="menu_1"
                  :close-on-content-click="false"
                  :nudge-right="20"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      outlined
                      rounded
                      dense
                      label="Fecha inicial"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    scrollable
                    v-model="date"
                    locale="es-co"
                    color="primary"
                    first-day-of-week="1"
                    @input="menu_1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="4" sm="6">
                <v-menu
                  v-model="menu_2"
                  :close-on-content-click="false"
                  :nudge-right="20"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date_fin"
                      outlined
                      rounded
                      dense
                      label="Fecha final"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    scrollable
                    v-model="date_fin"
                    locale="es-co"
                    color="primary"
                    first-day-of-week="1"
                    @input="menu_2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <v-data-table
                  dense
                  :headers="headers"
                  :items="lista_productos"
                  :search="search"
                  :footer-props="{ itemsPerPageText: 'Productos' }"
                >
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-row dense>
                        <v-col cols="4" class="px-0">
                          <v-text-field
                            outlined
                            rounded
                            dense
                            append-icon="mdi-magnify"
                            label="Buscar"
                            v-model="search"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-btn color="black" dark @click="download()">
                            <v-icon>mdi-download</v-icon>
                            Descargar
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-toolbar>
                  </template>
                  <template v-slot:item.compra="{ item }">
                    {{ item.compra | currency }}
                  </template>
                  <template v-slot:item.promedio_s="{ item }">
                    {{ item.promedio_s | currency }}
                  </template>
                  <template v-slot:item.promedio_i="{ item }">
                    {{ item.promedio_i | currency }}
                  </template>
                  <template v-slot:item.costo="{ item }">
                    {{ item.costo | currency }}
                  </template>
                  <template v-slot:item.subtotal="{ item }">
                    {{ item.subtotal | currency }}
                  </template>
                  <template v-slot:item.iva="{ item }">
                    {{ item.iva | currency }}
                  </template>
                  <template v-slot:item.total="{ item }">
                    {{ item.total | currency }}
                  </template>
                  <template v-slot:item.margen="{ item }">
                    {{ item.margen | currency }}
                  </template>
                  <template v-slot:item.detalle="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          fab
                          small
                          color="primary"
                          v-on="on"
                          v-bind="attrs"
                          @click="openDetalle(item)"
                          class="my-1"
                        >
                          <v-icon>mdi-eye</v-icon>
                        </v-btn>
                      </template>
                      <span>Ver detalle</span>
                    </v-tooltip>
                  </template>
                  <template v-slot:body.append>
                    <tr>
                      <th colspan="2">
                        Totales
                      </th>
                      <th colspan="4">
                        {{ totalizacion.cantidad }}
                      </th>
                      <th>
                        {{ totalizacion.costo | currency }}
                      </th>
                      <th>
                        {{ totalizacion.ventas | currency }}
                      </th>
                      <th>
                        {{ totalizacion.iva | currency }}
                      </th>
                      <th>
                        {{ totalizacion.total | currency }}
                      </th>
                      <th colspan="3">
                        {{ totalizacion.margen | currency }}
                      </th>
                    </tr>
                  </template>
                </v-data-table>
                <v-dialog v-model="dialog" scrollable max-width="800px">
                  <v-card v-if="detalle">
                    <v-card-title>
                      {{ detalle.producto }}
                    </v-card-title>
                    <v-card-text>
                      <v-data-table
                        :search="search_detalle"
                        dense
                        :headers="SubHeader"
                        :items="detalle.data"
                        class="mt-2"
                        :footer-props="{ itemsPerPageText: 'Facturas' }"
                      >
                        <template v-slot:top>
                          <v-toolbar flat>
                            <v-row dense>
                              <v-col cols="4" class="px-0">
                                <v-text-field
                                  outlined
                                  rounded
                                  dense
                                  append-icon="mdi-magnify"
                                  label="Buscar"
                                  v-model="search_detalle"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="4">
                                <v-btn
                                  @click="downloadDetalle()"
                                  color="black"
                                  dark
                                >
                                  <v-icon>mdi-download</v-icon>
                                  Descargar
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-toolbar>
                        </template>
                        <template v-slot:item.fecha="{ item }">
                          {{ moment(item.fecha).format("ll") }}
                        </template>
                        <template v-slot:item.folio="{ item }">
                          <router-link
                            :to="'/detallefactura/' + item.id_factura"
                          >
                            <span>
                              {{ item.folio }}
                            </span>
                          </router-link>
                        </template>
                        <template v-slot:item.subtotal="{ item }">
                          {{ item.subtotal | currency }}
                        </template>
                        <template v-slot:item.iva="{ item }">
                          {{ item.iva | currency }}
                        </template>
                        <template v-slot:item.total="{ item }">
                          {{ item.total | currency }}
                        </template>
                      </v-data-table>
                    </v-card-text>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import * as moment from "moment";
moment.locale("es");
import XLSX from "xlsx";
export default {
  data: () => ({
    dialog: false,
    date: moment().format("YYYY-MM-DD"),
    menu_1: false,
    date_fin: moment().format("YYYY-MM-DD"),
    menu_2: false,
    productos: [],
    search: "",
    search_detalle: "",
    headers: [
      {
        align: "start",
        text: "Código",
        value: "codigo",
        sortable: false,
        width: 50,
      },
      {
        text: "Producto",
        value: "producto",
        sortable: false,
        width: 120,
      },
      {
        text: "Cant",
        value: "cantidad",
        filterable: false,
        width: 50,
      },
      {
        text: "Precio compra",
        value: "compra",
        sortable: false,
        filterable: false,
        width: 90,
      },
      {
        text: "Venta promedio",
        value: "promedio_s",
        sortable: false,
        filterable: false,
        width: 90,
      },
      {
        text: "IVA promedio",
        value: "promedio_i",
        sortable: false,
        filterable: false,
        width: 90,
      },
      {
        text: "Costo",
        value: "costo",
        sortable: false,
        filterable: false,
        width: 90,
      },
      {
        text: "Ventas",
        value: "subtotal",
        sortable: false,
        filterable: false,
        width: 90,
      },
      {
        text: "IVA",
        value: "iva",
        sortable: false,
        filterable: false,
        width: 90,
      },
      {
        text: "Total",
        value: "total",
        width: 90,
        filterable: false,
      },
      {
        text: "Ganancia",
        value: "margen",
        width: 90,
        filterable: false,
      },
      {
        text: "Utilidad",
        value: "utilidad",
        sortable: false,
        filterable: false,
        width: 50,
      },
      {
        text: "Detalle",
        value: "detalle",
        sortable: false,
        filterable: false,
        width: 50,
      },
    ],
    detalle: null,
    SubHeader: [
      {
        text: "Fecha",
        value: "fecha",
        align: "start",
      },
      {
        text: "Factura",
        value: "folio",
      },
      {
        text: "Cliente",
        value: "cliente",
        sortable: false,
      },
      {
        text: "Cant",
        value: "cantidad",
      },
    ],
  }),
  methods: {
    loadVentas() {
      const body = {
        route: "/consumos_productos",
        params: {
          date: JSON.stringify({ inicio: this.date, fin: this.date_fin }),
        },
      };
      this.$store.dispatch("get_data", body).then((response) => {
        if (response.data.success) {
          this.productos = response.data.data;
        }
      });
    },
    openDetalle(item) {
      this.detalle = {
        producto: item.producto,
        codigo: item.codigo,
        data: item.data,
      };
      this.dialog = true;
    },
    download() {
      const name = "Productos-Ventas";
      let data = this.lista_productos.map((producto) => {
        return {
          Columna_A: producto.codigo,
          Columna_B: producto.producto,
          Columna_C: producto.cantidad,
          Columna_D: producto.compra,
          Columna_E: producto.promedio_s,
          Columna_F: producto.promedio_i,
          Columna_G: producto.costo,
          Columna_H: producto.subtotal,
          Columna_I: producto.iva,
          Columna_J: producto.total,
          Columna_K: producto.margen,
          Columna_L: producto.utilidad,
        };
      });
      data.unshift(
        {
          Columna_A: "Fecha inicial",
          Columna_B: this.moment(this.date).format("ll"),
          Columna_C: "",
          Columna_D: "",
          Columna_E: "",
          Columna_F: "",
          Columna_G: "",
          Columna_H: "",
          Columna_I: "",
          Columna_J: "",
          Columna_K: "",
          Columna_L: ""
        },
        {
          Columna_A: "Fecha final",
          Columna_B: this.moment(this.date_fin).format("ll"),
          Columna_C: "",
          Columna_D: "",
          Columna_E: "",
          Columna_F: "",
          Columna_G: "",
          Columna_H: "",
          Columna_I: "",
          Columna_J: "",
          Columna_K: "",
          Columna_L: ""
        },
        {
          Columna_A: "Codigo",
          Columna_B: "Producto",
          Columna_C: "Cantidad",
          Columna_D: "Precio compra",
          Columna_E: "Venta promedio",
          Columna_F: "IVA promedio",
          Columna_G: "Costo",
          Columna_H: "Ventas",
          Columna_I: "IVA",
          Columna_J: "Total",
          Columna_K: "Ganancia",
          Columna_L: "Utilidad",
        }
      );
      data.push(
        {
          Columna_A: "",
          Columna_B: "",
          Columna_C: "",
          Columna_D: "",
          Columna_E: "",
          Columna_F: "",
          Columna_G: "",
          Columna_H: "",
          Columna_I: "",
          Columna_J: "",
          Columna_K: "",
          Columna_L: ""
        },
        {
          Columna_A: "Totales",
          Columna_B: "",
          Columna_C: this.totalizacion.cantidad,
          Columna_D: "",
          Columna_E: "",
          Columna_E: "",
          Columna_F: this.totalizacion.costo,
          Columna_G: this.totalizacion.ventas,
          Columna_G: this.totalizacion.iva,
          Columna_G: this.totalizacion.total,
          Columna_H: this.totalizacion.margen,
          Columna_I: "",
        }
      );
      let excel = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      const filename = name;
      XLSX.utils.book_append_sheet(workbook, excel, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },
    downloadDetalle() {
      const name = `Consumos-producto-${this.detalle.codigo}`;
      let data = this.detalle.data.map((item) => {
        return {
          Columna_A: this.moment(item.fecha).format("ll"),
          Columna_B: this.$options.filters.folio(item.folio),
          Columna_C: item.cliente,
          Columna_D: item.cantidad,
        };
      });
      data.unshift(
        {
          Columna_A: "Fecha inicial",
          Columna_B: this.moment(this.date).format("ll"),
          Columna_C: "",
          Columna_D: "",
        },
        {
          Columna_A: "Fecha final",
          Columna_B: this.moment(this.date_fin).format("ll"),
          Columna_C: "",
          Columna_D: "",
        },
        {
          Columna_A: "Fecha",
          Columna_B: "Factura",
          Columna_C: "Cliente",
          Columna_D: "Cantidad",
        }
      );
      let excel = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      const filename = name;
      XLSX.utils.book_append_sheet(workbook, excel, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },
    moment(input) {
      return moment(input);
    },
  },
  created() {
    this.loadVentas();
  },
  mounted() {
    this.$store.dispatch("loadNav", true);
    const body = {
      permisos: JSON.stringify(["informes"]),
    };
    this.$store
      .dispatch("tokenValid", body)
      .then((response) => {
        if (!response.data.access_view) {
          this.$router.push("/home");
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status == 401) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("session_start");
          window.location.reload();
        }
      });
  },
  computed: {
    lista_productos() {
      let lista = this.productos;
      let productos = lista.reduce((grupo, item) => {
        let id = item["producto_id"];
        grupo[id] = grupo[id] || {
          producto_id: null,
          compra: 0,
          producto: "",
          sistema: "",
          codigo: "",
          cantidad: 0,
          subtotal: 0,
          iva: 0,
          total: 0,
          costo: 0,
          promedio_s: 0,
          promedio_i: 0,
          margen: 0,
          utilidad: 0,
          data: [],
        };

        grupo[id].producto_id = item.producto_id;
        grupo[id].compra = parseFloat(item.compra);
        grupo[id].producto = item.producto;
        grupo[id].sistema = item.sistema;
        grupo[id].codigo = item.codigo;
        grupo[id].cantidad += parseFloat(item.cantidad);
        grupo[id].subtotal += parseFloat(item.subtotal);
        grupo[id].iva += parseFloat(item.iva);
        grupo[id].total += parseFloat(item.total);
        grupo[id].costo = grupo[id].cantidad * grupo[id].compra;
        grupo[id].promedio_s = grupo[id].subtotal / grupo[id].cantidad;
        grupo[id].promedio_i = grupo[id].iva / grupo[id].cantidad;
        grupo[id].margen =
          grupo[id].subtotal - grupo[id].cantidad * grupo[id].compra;
        grupo[id].utilidad =
          ((grupo[id].promedio_s - grupo[id].compra) / grupo[id].promedio_s) *
          100;
        grupo[id].data.push({
          cliente: item.cliente,
          folio: this.$options.filters.folio(item.folio),
          id_factura: item.id_factura,
          fecha: item.fecha,
          cantidad: parseFloat(item.cantidad),
          subtotal: item.subtotal,
          iva: item.iva,
          total: item.total,
        });
        grupo[id].data = grupo[id].data.sort((a, b) => a.fecha > b.fecha || -1);
        return grupo;
      }, {});
      productos = Object.values(productos).map((producto) => {
        producto.utilidad = isNaN(producto.utilidad)
          ? 0
          : !isFinite(producto.utilidad)
          ? 0
          : producto.utilidad;
        producto.utilidad = this.$options.filters.utility(producto.utilidad);
        return producto;
      });
      return productos.sort((a, b) => a.cantidad < b.cantidad || -1);
    },
    totalizacion() {
      return {
        cantidad: this.lista_productos.reduce((index, item) => {
          return index + item.cantidad;
        }, 0),
        costo: this.lista_productos.reduce((index, item) => {
          return index + item.costo;
        }, 0),
        ventas: this.lista_productos.reduce((index, item) => {
          return index + item.subtotal;
        }, 0),
        iva: this.lista_productos.reduce((index, item) => {
          return index + item.iva;
        }, 0),
        total: this.lista_productos.reduce((index, item) => {
          return index + item.total;
        }, 0),
        margen: this.lista_productos.reduce((index, item) => {
          return index + item.margen;
        }, 0),
      };
    },
  },
  watch: {
    date(val) {
      this.loadVentas();
      return this.date;
    },
    date_fin(val) {
      this.loadVentas();
      return this.date_fin;
    },
  },
};
</script>
